import { theme } from "@chakra-ui/core";

const customTheme = {
  ...theme,
  breakpoints: ["50em", "69em"],
  fonts: {
    body: "Kollektif, Fira Sans Condensed, sans-serif",
    heading: "Playfair Display, serif",
    mono: "Menlo, monospace",
  },
  colors: {
    ...theme.colors,
    brand: {
      queen: "#3a6ea6",
      isa: "#f4f0ec",
      mango: "#ff9048",
      oxford: "#0d2440",
      winter: "#EB3978",
    },
  },
};

export default customTheme;
